import React from 'react'
import { Link, graphql } from 'gatsby'
import '../styles/generic-intro.css'
import { symbolResize } from '../helper-functions'

export default function CenteredQuote({ layoutPiece }) {
  const button = layoutPiece?.introButton 

  function GetBackgroundColor() {
    switch (layoutPiece?.backgroundColor) {
      case 'Black':
        return "generic-intro dark-background"
      case 'Grey':
        return "generic-intro grey-background"
      case 'Off-white':
        return "generic-intro eggshell-background"
      default:
        return "generic-intro"
    }
  }
  return (
    <div className={GetBackgroundColor()}>
      <div className="content-cap">
        <div className="generic-align">
          <h2>{symbolResize(layoutPiece?.introTitle)}</h2>
          <p>{layoutPiece?.introText}</p>
          {
            button && button?.fullUrl ?
              <a className="button-default solid-dark-button" href={button?.fullUrl}>{button?.buttonText}</a>
            : ""
          }
          {
            button && button?.slug ?
              <Link className="button-default solid-dark-button" to={button?.slug}>{button?.buttonText}</Link>
            : ""
          }
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment GlobalIntro on ContentfulGlobalIntro {
    id
    backgroundColor
    introTitle
    introText
    introButton {
      buttonText
      fullUrl
      slug
    }
  }
`
