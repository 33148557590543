import * as React from "react"
import { Link } from 'gatsby'
import Layout from '../components/layout'
//import component list
import GlobalIntro from '../components/global-intro'
import LegalSection from '../components/legal-section'

const ContentArea = ({layoutPieces}) => {
    if (layoutPieces) {
      return layoutPieces.map((layoutPiece, index) => {
        return ([
          layoutPiece.internal.type === 'ContentfulGlobalIntro' && <GlobalIntro layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulLegalPageComponent' && <LegalSection layoutPiece={layoutPiece} key={index}/>,
        ])
      })
    }
}

export default ContentArea