import * as React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout'
//import component pieces
import LegalContentArea from '../templates/legal-content-area'
import ogMetaImage from '../../static/entratalogo_1200x630.png'

export default function LegalPage({ data }) {
    const layoutPieces = data.contentfulLegalTextPageTemplate1.legalPageComponents

  return (
    <Layout header={data?.contentfulLegalTextPageTemplate1?.header} footer={data?.contentfulLegalTextPageTemplate1?.footer}>
      <LegalContentArea layoutPieces={layoutPieces}/>
    </Layout>
  )
}

export const Head = ({data}) => (
  <>
    <meta name="description" content={data?.contentfulLegalTextPageTemplate1?.metaDescription} />
    <title>{data?.contentfulLegalTextPageTemplate1?.metaTitle}</title>
    <meta property="og:title" content={data?.contentfulLegalTextPageTemplate1?.ogMetaTitle ? data.contentfulLegalTextPageTemplate1.ogMetaTitle : data?.contentfulLegalTextPageTemplate1?.metaTitle} />
    <meta property="og:description" content={data?.contentfulLegalTextPageTemplate1?.ogMetaDescription ? data.contentfulLegalTextPageTemplate1.ogMetaDescription : data?.contentfulLegalTextPageTemplate1?.metaDescription} />
    <meta property="og:type" content={data?.contentfulLegalTextPageTemplate1?.ogMetaType} />
    <meta property="og:url" content={data?.contentfulLegalTextPageTemplate1?.slug ? `https://www.entrata.com${data?.contentfulLegalTextPageTemplate1?.slug}` : undefined} />
    <meta property="og:image" content={data?.contentfulLegalTextPageTemplate1?.ogMetaImage?.image ? data?.contentfulLegalTextPageTemplate1?.ogMetaImage?.image?.url : ogMetaImage} />
  </>
)

export const query = graphql`
    query LegalComponentPieces($slug: String!) {
        contentfulLegalTextPageTemplate1(slug: { eq: $slug }) {
            slug
            header
            footer
            metaDescription
            metaTitle
            ogMetaTitle
            ogMetaDescription
            ogMetaType
            ogMetaImage {
                image {
                    url
                }
            }
            legalPageComponents {
                ... on Node {
                    internal {
                        type
                    }
                    ... on ContentfulGlobalIntro {
                        ...GlobalIntro
                    }
                    ... on ContentfulLegalPageComponent {
                        ...LegalPageComponent
                    }
                }
            }
        }
    }
`